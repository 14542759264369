import React from "react";
import profile from "../../assets/pic/profile-pic.png";
import Container from "react-bootstrap/Container";
import "./About.css";
import ScrollAnimation from "react-animate-on-scroll";

const About = () => {
  return (
    <Container className="about">
      <div className="about-main">
        <div className="about-img">
          <ScrollAnimation animateIn="fadeInLeft">
            <img src={profile} alt="profile" height="480" />
          </ScrollAnimation>
        </div>
        <div className="about-text">
          <p className="text-justify">
            Welcome to my portfolio page. I'm Vy and I am working on becoming a
            Web Developer skilled also in designing. Along the journey, the more
            I learn, the more I love the process of creating digital products.
            And I particularly share my big interest to Front-end Development.
            In my free time, I like drawing and designing visual materials,
            gardening and finding good music from SoundCloud.
          </p>
        </div>
        <div className="motion-cv video-responsive">
          <iframe
            title="vimeo-player"
            src="https://player.vimeo.com/video/452512179"
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen
            className="video-responsive-item"
          ></iframe>
        </div>
      </div>
    </Container>
  );
};

export default About;
