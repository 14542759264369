import React from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import NavDropdown from "react-bootstrap/NavDropdown";
// import Button from "react-bootstrap/Button";
// import FormControl from "react-bootstrap/FormControl";
import Form from "react-bootstrap/Form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLinkedin,
  faGithub,
  faBehance,
} from "@fortawesome/free-brands-svg-icons";
import "./Navbar.css";
import { BrowserRouter as Router, Switch, Route, Link } from "react-router-dom";
import Banner from "../Welcome/Banner";
import About from "../About/About";
import Skills from "../Skills/Skills";
import ContactForm from "../Contact/Contact";
import Projects from "../Projects/Projects";
import Design from "../Design/Design";
const MainNav = () => {
  return (
    <Router>
      <div>
        <Navbar bg="light" expand="lg" fixed="top">
          <Navbar.Brand>
            <Link
              to="/"
              className="nav-brand"
              style={{ textDecoration: "none", color: "rgb(83, 83, 83)" }}
            >
              Vy Pham
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="mr-auto">
              <Link to="/about" className="nav-link">
                About
              </Link>

              <Link to="/skills" className="nav-link">
                Skills
              </Link>

              <NavDropdown title="Projects" id="basic-nav-dropdown">
                <NavDropdown.Item href="/projects">
                  Web Development
                </NavDropdown.Item>
                <NavDropdown.Item href="/interface-designs">
                  UI/UX Design
                </NavDropdown.Item>

                {/* <Link to="/interface-designs" className="nav-link">
                  UI/UX Design
                </Link> */}
              </NavDropdown>

              <Link to="/contact" className="nav-link">
                Contact me!
              </Link>
            </Nav>
            <Form inline>
              <a
                href="https://github.com/vyphamht"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faGithub} />
              </a>
              <a
                href="https://www.behance.net/vyphamht"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faBehance} />
              </a>
              <a
                href="https://linkedin.com/in/vyphamht"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon icon={faLinkedin} />
              </a>
            </Form>
          </Navbar.Collapse>
        </Navbar>
        <Switch>
          <Route exact path="/" component={Banner}></Route>
          <Route exact path="/about" component={About}></Route>
          <Route path="/skills" component={Skills}></Route>
          <Route path="/contact" component={ContactForm} />
          <Route path="/projects" component={Projects} />
          <Route path="/interface-designs" component={Design} />
        </Switch>
      </div>
    </Router>
  );
};

export default MainNav;
