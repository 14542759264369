import React from "react";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import CardDeck from "react-bootstrap/CardDeck";
import "./Projects.css";
import playlist from "../../assets/pic/playlist.png";
import lyrics from "../../assets/pic/lyrics-app.png";
import spaceFacts from "../../assets/pic/space-fact.png";
import mealPlan from "../../assets/pic/mealPlan.png";
import ScrollAnimation from "react-animate-on-scroll";

const Projects = () => {
  return (
    <div
      className="projects"
      style={{ display: "flex", justifyContent: "center" }}
    >
      <CardDeck style={{ marginTop: "3rem" }}>
        <ScrollAnimation animateIn="fadeInDown">
          <Card style={{ maxWidth: "300px" }}>
            <Card.Img variant="top" src={playlist} />
            <Card.Body>
              <Card.Title>Playlist of the Day</Card.Title>
              <Card.Text>
                This is group work summer project used for pick up a playlist
                which user can stay with along his/her day. The application was
                inspired by the forest app fitting relaxing mood and slow-paced
                morning.
              </Card.Text>
              <Card.Text>
                My role: UI design, app logic, test and design API request
              </Card.Text>
              <Card.Text>
                Tools: React Hooks, Bootstrap, Spotify API, MongoDB,
                localStorage
              </Card.Text>
            </Card.Body>
            <Button
              style={{
                backgroundColor: "#ffd609",
                borderColor: "black",
                borderWidth: "2px",
                marginBottom: "5px",
                color: "black",
              }}
              href="https://daily-playlist-frontend.herokuapp.com/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Try this app
            </Button>
            <Button
              style={{
                backgroundColor: "#ffd609",
                borderColor: "black",
                borderWidth: "2px",
                color: "black",
              }}
              href="https://github.com/heihazy/daily-playlist-frontend"
              target="_blank"
              rel="noopener noreferrer"
            >
              See code
            </Button>
          </Card>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp">
          <Card style={{ maxWidth: "300px" }}>
            <Card.Img variant="top" src={lyrics} />
            <Card.Body>
              <Card.Title>Lyrics App</Card.Title>
              <Card.Text>
                I always wanted to have an app for searching lyrics alone. Glad
                that I could finally did to practice React and Redux with big
                help from online tutorials
              </Card.Text>
              <Card.Text>My role: Creator</Card.Text>
              <Card.Text>
                Tools: React and Redux, Bootstrap, Musixmatch API, Firebase
              </Card.Text>
            </Card.Body>
            <Button
              style={{
                backgroundColor: "#ffd609",
                borderColor: "black",
                borderWidth: "2px",
                marginBottom: "5px",
                color: "black",
              }}
              href="https://lyrics-app-v.web.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Try this app
            </Button>
            <Button
              style={{
                backgroundColor: "#ffd609",
                borderColor: "black",
                borderWidth: "2px",
                color: "black",
              }}
              href="https://github.com/phamhavy171/lyrics-app"
              target="_blank"
              rel="noopener noreferrer"
            >
              See code
            </Button>
          </Card>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInDown">
          <Card style={{ maxWidth: "300px" }}>
            <Card.Img variant="top" src={spaceFacts} />
            <Card.Body>
              <Card.Title>Space Facts</Card.Title>
              <Card.Text>
                This is a fun project that I made for a friend of mine. It's
                dedicated for contributing to a collection of random facts about
                space and universe. This app was made with Materialize modal and
                data is stored with Cloud Firebase.
              </Card.Text>
              <Card.Text>My role: Creator</Card.Text>
              <Card.Text>Tools: Materialize and Firebase</Card.Text>
            </Card.Body>
            <Button
              style={{
                backgroundColor: "#ffd609",
                borderColor: "black",
                borderWidth: "2px",
                marginBottom: "5px",
                color: "black",
              }}
              href="https://space-facts.netlify.app/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Try this app
            </Button>
            <Button
              style={{
                backgroundColor: "#ffd609",
                borderColor: "black",
                borderWidth: "2px",
                color: "black",
              }}
              href="https://github.com/phamhavy171/space-facts"
              target="_blank"
              rel="noopener noreferrer"
            >
              See code
            </Button>
          </Card>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInUp">
          <Card style={{ maxWidth: "300px" }}>
            <Card.Img variant="top" src={mealPlan} />
            <Card.Body>
              <Card.Title>Meal Plan</Card.Title>
              <Card.Text>
                This is a group project in the end of my first semester. This
                app is designed to search meal recipes with API requests
                according to user calorie specification
              </Card.Text>
              <Card.Text>
                My role: design API requests for filter page and UI design
              </Card.Text>
              <Card.Text>
                Tools: React Hooks, Router, Boostrap, NodeMailer and Edamam API
              </Card.Text>
            </Card.Body>

            <Button
              style={{
                backgroundColor: "#ffd609",
                borderColor: "black",
                borderWidth: "2px",
                color: "black",
              }}
              href="https://github.com/phamhavy171/Meal-Plan-App"
              target="_blank"
              rel="noopener noreferrer"
            >
              See code
            </Button>
          </Card>
        </ScrollAnimation>
      </CardDeck>
    </div>
  );
};

export default Projects;
