import React from "react";
import "./Contact.css";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhoneSquare,
  faEnvelopeSquare,
} from "@fortawesome/free-solid-svg-icons";
import ScrollAnimation from "react-animate-on-scroll";

class ContactForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      email: "",
      message: "",
    };
  }
  // http://localhost:5000/send
  handleSubmit(e) {
    e.preventDefault();
    fetch("https://vyphamht-backend.herokuapp.com/send", {
      method: "POST",
      body: JSON.stringify(this.state),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((response) => {
        if (response.status === "success") {
          alert("Message Sent.");
          this.resetForm();
        } else if (response.status === "fail") {
          alert("Message failed to send.");
        }
      });
  }

  resetForm() {
    this.setState({ name: "", email: "", message: "" });
  }

  render() {
    return (
      <div className="contact-form">
        <Container>
          <Col md={{ span: 8, offset: 2 }}>
            <h2 style={{ textAlign: "center", padding: "20px" }}>
              Write to me!
            </h2>
            <Form onSubmit={this.handleSubmit.bind(this)} method="POST">
              <Form.Group controlId="formName">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  value={this.state.name}
                  onChange={this.onNameChange.bind(this)}
                  style={{
                    borderColor: "black",
                    borderWidth: "2px",
                  }}
                />
              </Form.Group>

              <Form.Group controlId="formEmail">
                <Form.Label>Email</Form.Label>
                <Form.Control
                  type="email"
                  value={this.state.email}
                  onChange={this.onEmailChange.bind(this)}
                  style={{
                    borderColor: "black",
                    borderWidth: "2px",
                  }}
                />
              </Form.Group>

              <Form.Group controlId="textarea">
                <Form.Label>Message</Form.Label>
                <Form.Control
                  as="textarea"
                  rows="3"
                  value={this.state.message}
                  onChange={this.onMessageChange.bind(this)}
                  style={{
                    borderColor: "black",
                    borderWidth: "2px",
                  }}
                />
              </Form.Group>

              <Button
                type="submit"
                style={{
                  backgroundColor: "#ffd609",
                  borderColor: "black",
                  borderWidth: "2px",
                  color: "black",
                }}
              >
                Send
              </Button>
            </Form>
          </Col>
        </Container>
        <Container className="other-contact">
          <ScrollAnimation animateIn="fadeInLeft">
            <h2 style={{ textAlign: "center", padding: "20px" }}>
              Or connect with me via:
            </h2>
          </ScrollAnimation>
          <ScrollAnimation animateIn="fadeInRight">
            <div className="other-contact-box">
              <Col xs={6} md={4}>
                <a
                  href="Tel:+358465221171"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faPhoneSquare} /> 046 522 1171
                </a>
              </Col>
              <Col xs={6} md={4}>
                <a
                  href="mailto:vyphamht@gmail.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <FontAwesomeIcon icon={faEnvelopeSquare} /> vyphamht@gmail.com
                </a>
              </Col>
            </div>
          </ScrollAnimation>
        </Container>
      </div>
    );
  }

  onNameChange(event) {
    this.setState({ name: event.target.value });
  }

  onEmailChange(event) {
    this.setState({ email: event.target.value });
  }

  onMessageChange(event) {
    this.setState({ message: event.target.value });
  }
}

export default ContactForm;
