import React from "react";
import Jumbotron from "react-bootstrap/Jumbotron";
import { Link } from "react-router-dom";
import "./Banner.css";
const Banner = () => {
  return (
    <div className="banner-whole">
      <Jumbotron>
        <div className="banner-text">
          <h1>Hello!</h1>
          <p>
            My name is Vy Pham. I'm a junior front-end developer and a product
            designer.
          </p>
          <p>
            <Link
              variant="primary"
              className="btn btn-primary"
              style={{
                backgroundColor: "#ffd609",
                borderColor: "black",
                borderWidth: "2px",
                color: "black",
              }}
              to="/contact"
            >
              Drop me a message!
            </Link>
          </p>
        </div>
      </Jumbotron>
    </div>
  );
};

export default Banner;
