import React from "react";
import "./App.css";
import Nav from "./Components/Navbar/Navbar";
// import Banner from "./Components/Welcome/Banner";
// import About from "./Components/About/About";
// import ContactForm from "./Components/Contact/Contact";
import Footer from "./Components/Footer/Footer";
// import Skills from "./Components/Skills/Skills";

function App() {
  return (
    <div>
      <Nav />
      <Footer />
    </div>
  );
}

export default App;
