import React from "react";
import "./Design.css";
import easyCookies from "../../assets/pic/easyCookies.png";
import goodWood from "../../assets/pic/goodWood.png";

const Design = () => {
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
      }}
      className="container"
    >
      <div className="tiles">
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="tile"
          href="https://www.behance.net/gallery/93237861/easy-cookies-Mobile-App"
        >
          <img alt="description of easy cookies project" src={easyCookies} />
          <div className="details">
            <span className="title">Easy Cookies</span>
            <span className="info">
              easy cookies is an application providing cookie recipes for baking
              enthusiasts
            </span>
          </div>
        </a>
        <a
          rel="noopener noreferrer"
          target="_blank"
          className="tile"
          href="https://www.behance.net/gallery/94426161/GoodWood-Workshop"
        >
          <img src={goodWood} alt="description of good wood project" />
          <div className="details">
            <span className="title">Good Wood</span>
            <span className="info">
              Little wood workshop creating tiny decorative wooden sculptures
              and high quality wooden kid toys
            </span>
          </div>
        </a>
      </div>
    </div>
  );
};

export default Design;
