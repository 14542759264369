import React from "react";
import ProgressBar from "react-bootstrap/ProgressBar";
import "./Skills.css";
import ScrollAnimation from "react-animate-on-scroll";
const Skills = () => {
  return (
    <div className="skill-section">
      <div className="skills">
        <ScrollAnimation animateIn="fadeInDown">
          <div className="design-skills">
            <h2>Design Tools</h2>
            <div className="skill-bars designing">
              <ProgressBar animated label="Adobe Illustrator" now={80} />
              <br />
              <ProgressBar animated now={60} label="Adobe After Effect" />
              <br />
              <ProgressBar animated now={70} label="Adobe Photoshop" />
              <br />
              <ProgressBar animated now={80} label="Figma" />
              <br />
              <ProgressBar animated now={90} label="Adobe Lightroom" />
              <br />
              <ProgressBar animated now={80} label="Sony Vegas" />
              <br />
              <ProgressBar animated now={90} label="Microsoft PowerPoint" />
              <br />
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInRight">
          <div className="coding-skills">
            <h2>Web Development</h2>
            <div className="skill-bars coding">
              <ProgressBar animated label="HTML5" now={90} />
              <br />
              <ProgressBar animated now={90} label="CSS3" />
              <br />
              <ProgressBar animated now={70} label="Javascript" />
              <br />
              <ProgressBar animated now={70} label="Git" />
              <br />
              <ProgressBar animated now={60} label="NodeJS" />
              <br />
              <ProgressBar animated now={70} label="React/Redux" />
              <br />
              <ProgressBar animated now={50} label="MongoDB" />
              <br />
              <ProgressBar animated now={40} label="Firebase" />
              <br />
              <ProgressBar animated now={60} label="Heroku" />
              <br />
            </div>
          </div>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeInLeft">
          <div className="managing-skills">
            <h2>Project Managament</h2>
            <div className="skill-bars managing">
              <ProgressBar animated label="Trello" now={90} />
              <br />
              <ProgressBar animated now={90} label="Slack" />
              <br />
              <ProgressBar animated now={70} label="Google suite" />
              <br />
            </div>
          </div>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default Skills;
